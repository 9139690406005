<template>

    <div>
        <div class="px-3 py-5 text-center" style="background-color: #3dac78;">
            <div class="my-5 mb-3 text-white fw-semibold">
                {{$t('login_description_one')}}
                <br />
                {{$t('login_description_two')}}

            </div>
        </div>

        <div class="mb-4 d-flex justify-content-center">
            <div class="btn-group rounded-4 mx-0 px-0 w-100" style="margin-top: 0.2px" role="group" aria-label="Basic example" >
                <router-link to="/register" type="button" class="btn btn-light rounded-0 rounded-end fw-normal py-2" :class="[1 == 0 ? 'pill-nav-active text-white shadow me-1' : 'pill-nav-inactive']" style="font-size: 14px;">{{$t('sign_up')}}</router-link>
                <router-link to="/login" type="button" class="btn btn-light rounded-0 rounded-start fw-normal py-2" :class="[1 == 1 ? 'pill-nav-active text-white shadow ms-1' : 'pill-nav-inactive']" style="font-size: 14px;">{{$t('login')}} </router-link>
            </div>
        </div>

        <div class="mt-5 px-4">

            <div class="form-outline">
                <label class="mb-2 fw-semibold">{{$t('phone_number')}}</label>
                <input type="text" class="form-control ps-5" @focus="password_search = false" v-model="serial_number" :placeholder="$t('phone_number')" style="border-color: #3dac78; height: 45px" />
                <Iconify class="inside-icon" icon="tabler:phone" />
            </div>

            <div class="form-outline">
                <label class="mb-2 fw-semibold">{{$t('password')}}</label>
                <input type="password" class="form-control ps-5" @focus="(serachPassword())" v-model="password" :placeholder="$t('password')" style="border-color: #3dac78; height: 45px" />
                <Iconify class="inside-icon" icon="mdi:password-outline" />
                
            </div>
            <div @click="usePasswordFromSearch()" v-if="password_search == true" class="bg-light rounded border ps-2 pt-2" style="position:relative ; top : -22px">
                <p class="ms-3 mb-0">{{serial_number}}</p>
                <span class="p-0 m-0" style="position:relative ; font-size : 25px ; left:18px ; bottom:10px">.........</span>
            </div>
            
            <div class="form-check">
                <input class="form-check-input" type="checkbox" v-model="remember_me" id="flexCheckDefault">
                <label class="form-check-label" for="flexCheckDefault">
                    记住密码
                </label>
            </div>

            <div class="d-flex justify-content-center">
                <button :disabled="loader ? true : false" class="btn col-12 text-white mt-2" style="background-color: #3dac78; width: 98%" @click="login()">{{$t('login')}}
                    <div class="lds-dual-ring" v-if="loader"></div>
                </button>
            </div>
        </div>
    </div>
    <!-- <div class="loginBg">
        <div class="d-flex justify-content-end me-0 mt-3 ">
            <div class="col-4">
                <select v-model="$i18n.locale" @change="lng_change($i18n.locale)" class="rounded" style="padding: 5px 15px;font-size: 16px;">
                    <option
                    v-for="locale in $i18n.availableLocales"
                    :key="`locale-${locale}`"
                    :value="locale"
                    >
                    {{ locale == 'hn' ? 'Hindi' : 'English' }} 
                    </option>
                </select>
            </div>
        </div>

        <div id="logintopParent" >
            
            <div class="text-center mb-3">
                    <img src="../assets/auth/logo4.png" style="margin-bottom : 4rem" class="img-fluid rounded" width="400">
                </div>
            <div class="card border-0">
               
                    <div class="card-body bg-dark p-5 pb-0">
                        <div class="mb-3">
                            <input v-model="serial_number" :placeholder="$t('phone_number')" autocomplete="new-phone" type="text" class="form-control" >
                        </div>
                        <div class="input mb-3">
                            <input v-model="password" type="password" :placeholder="$t('password')" class="form-control">
                        </div>
                        <div class="">
                            <button :disabled="loader ? true : false" class="btn col-12 text-white mt-2" style="background-color: #E25215;" @click="login()">{{$t('login')}}
                            <div class="lds-dual-ring" v-if="loader"></div>
                            </button>
                        </div>
            <router-link to="/register">
                <p class="text-center mt-2">{{$t('register')}}</p>
                </router-link>
                    </div>
            </div>

            
            
        </div>
    </div> -->
</template>

<script>
import { showSuccessToast , showFailToast } from 'vant';
import CryptoJS from 'crypto-js';

export default {
    data(){
        return {
            serial_number : null,
            password : null,
            loader : false,
            remember_me : false,
            password_search : false,
            password_from_search : null,
        }
    },
    methods : {
        lng_change(lng){
            let ln = lng == 'en' ? 'English' : 'Hindi'
            localStorage.setItem('ai_app_lng' , lng)
            showSuccessToast('Language changed to '+ln )
        },
        usePasswordFromSearch(){
            this.password_search = false
            this.password = this.password_from_search
        },
        serachPassword(){
            this.decrypt(this.serial_number)
        },

        login(){
            let point = this;
            if(this.serial_number != null && this.password != null){
                this.loader = true
                this.axios.post(process.env.VUE_APP_BASE_URL+'/enduser/signin',
                    {'serial_number' : this.serial_number ,  'password' : this.password}
                    ).then(function(response){
                        // console.log(response.data)
                        
                    if(response.data.status_code == 200){
                        showSuccessToast(point.$t(response.data.message));
                        if(point.remember_me){
                            point.encrypt(point.serial_number , point.password)
                        }
                        // point.$notify({ text: response.data.message,type: "success",});
                        localStorage.setItem('china_app_access_token' , JSON.stringify(response.data.data))
                        setTimeout(()=>{
                            window.location = '/'
                        },1000)
                    }else{
                        point.loader = false
                        showFailToast(point.$t(response.data.message));
                        // point.$notify({ text: response.data.message,type: "error",});
                    }
                    })
            }else{
                // showToast({
                //     icon : 'fail',
                //     message: 'Missing required fields',
                //     wordBreak: 'break-all',
                // });
                showFailToast(point.$t('missing_fields'));
                // showFailToast('Missing required fields');
            }
        },
        encrypt(key , password){
            const encryptedPassword = CryptoJS.AES.encrypt(password, 'f@rmErAppli2Tion2024END%@#$').toString();
            localStorage.setItem(key , encryptedPassword)
        },

        decrypt(user_serial_number){
            const key = localStorage.getItem(user_serial_number)
            if(key){
                const decryptedPassword = CryptoJS.AES.decrypt(key, 'f@rmErAppli2Tion2024END%@#$').toString(CryptoJS.enc.Utf8);
                this.password_from_search = decryptedPassword
                this.password_search = true
            }else{
                console.log('notfound')
            }
        }
    },
    created(){
        
    }
}
</script>

<style>

    .inside-icon {
        position: relative;
        top: -29px;
        left: 15px;
        transform: translateY(-50%);
        pointer-events: none;
        color: #a49c9c;
    }
</style>
