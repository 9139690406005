<template>
<div class="" style="background-color: white; overflow-x: hidden !important;">
    <div class="row border-bottom" style="">
        <div class="" style="color: #3dac78 !important; ">
            <router-link to="/mine">
                <Iconify class="" style="position : absolute ; top : 20px ; left : 20px" icon="weui:back-outlined"></Iconify>
            </router-link>

            <div class="text-center py-3" >{{ $t("verification") }}</div>
            
        </div>
    </div>

    <div class="my-3 px-4 mb-5" style="margin-bottom: 5rem !important;">
        <div class="" v-if="reopen_form == false" style="height: 100vh !important;">
            <div class="d-flex justify-content-center align-items-center">
                <img
                    src="../assets/verified/passedg1.png"
                    v-if="status_id == 2 && reopen_form == false"
                    class=""
                    style="margin-top: 6rem"
                    width="200"
                />
                <img
                    src="../assets/verified/pending.png"
                    v-if="status_id == 1"
                    class=""
                    style="margin-top: 6rem"
                    width="200"
                />
                <img
                    src="../assets/verified/reject.png"
                    v-if="status_id == 3 && reopen_form == false"
                    class=""
                    style="margin-top: 6rem"
                    width="200"
                />
            </div>
            <div class="" v-if="reopen_form == false">
                <h6 class="text-center mt-3 pb-3">
                    {{ $t(verified_text) }}
                </h6>

                <h6 class="text-center mt-3 pb-3">{{ remark }}</h6>
                <div class="d-flex justify-content-center" v-if="status_id == 3">
                    <button
                    class="btn col-6 text-white mt-5"
                    @click="reopen_form = true"
                    style="background-color: #e25215"
                    >
                    {{ $t("go_to_verification") }}
                    </button>
                </div>
            </div>
        </div>
        <div
            class="card border" style="border-style: dashed !important; border-color: #3dac78 !important;"
            v-if="verified == false || reopen_form"
            >
            <div class="card-body py-4">
                <div class="form-outline">
                    <!-- <label class="mb-2 fw-semibold">{{$t("actual_name")}}</label> -->
                    <input type="text" class="form-control" v-model="actual_name" :placeholder="$t('actual_name')" style="border: none; background-color: #eee" />
                </div>

                <div class="form-outline mt-4">
                    <!-- <label class="mb-2 fw-semibold">{{$t("id_number")}}</label> -->
                    <input type="text" class="form-control" v-model="idno" :placeholder="$t('id_number')" style="border: none; background-color: #eee" />
                </div>

                
            </div>
        </div>

        <div
            class="card mt-3" style="border-color: #3dac78; border-style: dashed !important;"
            v-if="verified == false || reopen_form"
          >
            <div class="card-body">
                <div class="">
                    <div
                        class="my-2 d-flex align-items-center border rounded p-3 bg-white"
                     >
                        <div class="py-2" style="width: 70%;">
                            <div class="mb-2" style="color: #3dac78 !important;">
                                {{$t("upload_back_id_photo")}}
                            </div>
                            <div class="me-1" style="font-size: 12px">
                                {{$t('verify_img_upload_desc')}}
                            </div>
                        </div>
                        <div v-if="preview1 == null" style="width: 30%">
                            <div class="d-flex justify-content-center align-items-center" >
                                <div>
                                    <input
                                        type="file"
                                        style="display: none"
                                        v-on:change="verifyImage($event, 1)"
                                        id="recharge_image_upload"
                                        name="file"
                                    />
                                    <label title="Image" for="recharge_image_upload">
                                        <Iconify icon="icon-park-outline:add" style="font-size: calc(2.6rem + 1.5vw) !important; color: rgb(61, 172, 120)" />
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div v-else class=" bg-white px-1 pb-1" style="width: 30%">
                            <div>
                                <fa
                                    class="text-danger float-end"
                                    style=""
                                    :icon="['fas', 'times']"
                                    @click="removeImage(1)"
                                ></fa>
                            </div>
                            <img :src="preview1" class="img-fluid border rounded" style="width: 100%; max-height: 80px;" />
                            
                        </div>
                    </div>
                    <!-- <p class="text-danger text-center" style="font-size: 12px" >点击上传手持身份证正面照</p> -->
                    
                </div>

                <div class="">
                    <div    
                        class="my-2 d-flex align-items-center border rounded p-3 bg-white"
                     >
                        <div class="py-2" style="width: 70%;">
                            <div class="mb-2" style="color: #3dac78 !important;">
                                {{$t("upload_front_id_photo")}}
                            </div>
                            <div class="me-1" style="font-size: 12px">
                                {{$t('verify_img_upload_desc')}}
                            </div>
                        </div>
                        <div v-if="preview2 == null" style="width: 30%">
                            <div class="d-flex justify-content-center align-items-center" >
                                <div>
                                    <input
                                        type="file"
                                        style="display: none"
                                        v-on:change="verifyImage($event, 2)"
                                        id="recharge_image_upload"
                                        name="file"
                                    />
                                    <label title="Image" for="recharge_image_upload">
                                        <Iconify icon="icon-park-outline:add" style="font-size: calc(2.6rem + 1.5vw) !important; color: rgb(61, 172, 120)" />
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div v-else class=" bg-white px-1 pb-1" style="width: 30%">
                            <div>
                                <fa
                                    class="text-danger float-end"
                                    style=""
                                    :icon="['fas', 'times']"
                                    @click="removeImage(2)"
                                ></fa>
                            </div>
                            <img :src="preview2" class="img-fluid border rounded" style="width: 100%; max-height: 80px;" />
                            
                        </div>
                    </div>
                    
                </div>

                <div class="">
                    <div    
                        class="my-2 d-flex align-items-center border rounded p-3 bg-white"
                     >
                        <div class="py-2" style="width: 70%;">
                            <div class="mb-2" style="color: #3dac78 !important;">
                                {{$t("upload_hold_id_photo")}}
                            </div>
                            <div class="me-1" style="font-size: 12px">
                                {{$t('verify_img_upload_desc')}}
                            </div>
                        </div>
                        <div v-if="preview3 == null" style="width: 30%">
                            <div class="d-flex justify-content-center align-items-center" >
                                <div>
                                    <input
                                        type="file"
                                        style="display: none"
                                        v-on:change="verifyImage($event, 3)"
                                        id="recharge_image_upload"
                                        name="file"
                                    />
                                    <label title="Image" for="recharge_image_upload">
                                        <Iconify icon="icon-park-outline:add" style="font-size: calc(2.6rem + 1.5vw) !important; color: rgb(61, 172, 120)" />
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div v-else class=" bg-white px-1 pb-1" style="width: 30%">
                            <div>
                                <fa
                                    class="text-danger float-end"
                                    style=""
                                    :icon="['fas', 'times']"
                                    @click="removeImage(3)"
                                ></fa>
                            </div>
                            <img :src="preview3" class="img-fluid border rounded" style="width: 100%; max-height: 80px;" />
                            
                        </div>
                    </div>
                    
                </div>

            <p class="mt-3" id="warning">
                {{ $t("upload_requirements") }}
            </p>
            <span id="warning" class="">{{ $t("w1") }}</span>
            <br />
            <span id="warning" class="">{{ $t("w2") }}</span>
            <br />
            <span id="warning" class="">{{ $t("w3") }}</span>
            <br />
            <span id="warning" class="">{{ $t("w4") }}</span>
        </div>
        <div class="p-3" style="">
            <button
                :disabled="loader ? true : false"
                class="btn col-12 text-white"
                @click="verifySubmit()"
                style="background-color: #e25215"
            >
                {{ $t("submit") }}
                <div class="lds-dual-ring" v-if="loader"></div>
            </button>
        </div>
        </div>
    </div>
</div>
</template>

<script>
import { showSuccessToast, showFailToast } from "vant";
import Compressor from 'compressorjs';

export default {
  data() {
    return {
      images_types: ["image/png", "image/jpg", "image/jpeg"],
      preview1: null,
      preview2: null,
      preview3: null,
      idno: null,
      formData: new FormData(),
      verified: false,
      verified_text: "",
      loader: false,
      actual_name: null,
      status_id: null,
      remark: null,
      reopen_form: true,
    };
  },

  methods: {
    removeImage(i) {
      if (i == 1) {
        this.preview1 = null;
      } else if (i == 2) {
        this.preview2 = null;
      } else {
        this.preview3 = null;
      }
    },

    // verifyImage(e, i) {
    //   const file = e.target.files[0];
    //   let size = file.size / (1024 * 1024);
    //   let actual_size = Number(size.toFixed(1));
    //   if (actual_size > 5) {
    //     showFailToast("f_img_size");
    //     // this.$notify({ text: 'Image too large',type: "error",});
    //     return;
    //   }
    //   if (this.images_types.includes(file.type)) {
    //     let link = URL.createObjectURL(file);
    //     if (i == 1) {
    //       this.preview1 = link;
    //       this.formData.append("id_in_hand", file);
    //     } else if (i == 2) {
    //       this.preview2 = link;
    //       this.formData.append("id_front", file);
    //     } else {
    //       this.preview3 = link;
    //       this.formData.append("id_back", file);
    //     }
    //   } else {
    //     showFailToast("f_img_type");

    //     // showFailToast('Failed to upload photo')
    //     // this.$notify({ text: '上传照片失败',type: "error",});
    //   }
    // },
    verifyImage(e, i) {
      const file = e.target.files[0];

      if (!file) {
        showFailToast("f_img_type");
        return;
      }

      const MAX_FILE_SIZE_MB = 5;
      const MAX_DIMENSION = 600;

      // Validate file size
      let size = file.size / (1024 * 1024);
      let actual_size = Number(size.toFixed(1));
      if (actual_size > MAX_FILE_SIZE_MB) {
        showFailToast("f_img_size");
        return;
      }

      // Validate file type
      if (!this.images_types.includes(file.type)) {
        showFailToast("f_img_type");
        return;
      }

      new Compressor(file, {
        quality: 0.6,
        maxWidth: MAX_DIMENSION,
        maxHeight: MAX_DIMENSION,
        success: (compressedFile) => {
          console.log("Compressed file:", compressedFile);

          const previewFields = {
            1: "preview1",
            2: "preview2",
            3: "preview3",
          };
          const formDataFields = {
            1: "id_in_hand",
            2: "id_front",
            3: "id_back",
          };

          // Assign preview and append to formData
          if (previewFields[i] && formDataFields[i]) {
            let link = URL.createObjectURL(compressedFile);
            this[previewFields[i]] = link;
            this.formData.append(formDataFields[i], compressedFile);
          } else {
            showFailToast("Invalid image index");
          }
        },
        error: (err) => {
          console.error("Compression error:", err.message);
          showFailToast("f_img_type");
        },
      });
    },
    verifySubmit() {
      if (
        this.preview1 != null &&
        this.preview2 != null &&
        this.preview3 != null &&
        this.idno != null &&
        this.actual_name != null
      ) {
        // if (
        //   !isNaN(this.idno) &&
        //   this.idno.length >= 12 &&
        //   this.idno.length <= 21
        // ) {
          this.loader = true;
          let enduser_id = this.$store.state.users.id;
          this.formData.append("actual_name", this.actual_name);
          this.formData.append("enduser_id", enduser_id);
          this.formData.append("id_number", this.idno);
          let point = this;
          this.axios
            .post(
              process.env.VUE_APP_BASE_URL + "/realname/verification",
              this.formData,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                  Authorization:
                    "Bearer " +
                    JSON.parse(localStorage.getItem("china_app_access_token"))
                      .access_token,
                },
              }
            )
            .then(function (response) {
              console.log(response.data);
              if (response.data.status_code == 200) {
                console.log(response.data);
                showSuccessToast(point.$t('s_submit'));
                // point.$notify({ text: response.data.message,type: "",});
                setTimeout(() => {
                  location.reload();
                }, 1000);
              } else {
                showFailToast(point.$t(response.data.message));
                // point.$notify({ text: response.data.message,type: "error",});
                point.loader = false;
              }
            })
            .catch((error) => {
              if (error.response.status == 401) {
                localStorage.removeItem("china_app_access_token");
                location.reload();
              }
            });
        // } else {
        //   showFailToast(this.$t("wrong_id_number"));

        //   // this.$notify({ text: '证件号错误',type: "error",});
        // }
      } else {
        showFailToast(this.$t('missing_fields'));
        // this.$notify({ text: '缺少必填字段',type: "error",});
      }
    },
  },
  created() {
    let point = this;
    let enduser_id = this.$store.state.users.id;
    this.axios
      .post(
        process.env.VUE_APP_BASE_URL + "/realname/verification/check",
        { enduser_id: enduser_id },
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization:
              "Bearer " +
              JSON.parse(localStorage.getItem("china_app_access_token"))
                .access_token,
          },
        }
      )
      .then(function (response) {
        console.log(response.data);
        if (
          response.data.status_code == 200 ||
          response.data.status_code == 401
        ) {
          point.status_id = response.data.status_id;
          point.verified = true;
          point.verified_text = response.data.message;
          point.remark = response.data.data;
        if (response.data.status_code == 200) {
            point.reopen_form = false;
          }
          if (response.data.status_code == 401) {
            point.reopen_form = false;
          }
        }
      })
      .catch((error) => {
        if (error.response.status == 401) {
          localStorage.removeItem("china_app_access_token");
          location.reload()
        }
      });
  },
};
</script>

<style>
.header {
  background-color: #773df5;
}
#detail_body {
  margin-top: 1rem;
}
#detail_body .input-group-text {
  font-size: 12px;
}
#warning {
  font-size: 12px;
}
.text {
  font-size: 12px;
}
</style>
