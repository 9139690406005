<template>
    
    <div id="topParent">
        <div class="bg-image " id="head" style="z-index: -1 !important;"></div>
        <div class="px-4 pt-4 d-flex gap-2" style="z-index: 1 !important;">
            <!-- <h3 class="fw-bold">Hi, Jono</h3> -->
            <img src="../assets/home/brasilargo.png" alt="" srcset="" style="width: 120px">
            <img src="../assets/home/pokyi.png" alt="" srcset="">
            <span class="text-white" style="position:relative ; right : 10rem;top:2px"></span>
            
                <!-- <div class="text-white w-100 ps-2 rounded" style="background-color: #3DAC78;">Nanolabs</div> -->
            
        </div>
        <div class="py-4 px-4">
            <div class=" text-dark">
                <div class="card-body">
                    <div class="d-flex justify-content-between">
                        
                        <div>
                            <div class="d-flex  flex-column justify-content-between">
                                <div class="fs-6 mb-2">
                                    <span class="text-muted">{{$t('wallet_balance')}} (¥)</span>
                                    <span @click="balanceHide(false)"><Iconify  v-if="balance_show" class="ms-2" icon="iconoir:eye" style="font-size: calc(1.2rem + 1.5vw) !important;color: black ; font-weight:bold" /></span>
                                    <span @click="balanceHide(true)"><Iconify  v-if="!balance_show" class="ms-2" icon="fluent:eye-off-16-regular" style="font-size: calc(1.2rem + 1.5vw) !important;color: black ; font-weight:bold" /></span>
                                    <span class="badge rounded-pill text-success ps-2 pe-2 ms-2" style="background-color: rgba(61, 172, 120, 0.5);font-size: 10px"><Iconify style="position: relative ; bottom: 1px" icon="wpf:security-checked" /> 保障中</span>
                                </div>
                                <div>
                                    <div class="fs-3 fw-semibold">
                                        {{ balance_show ? Number(this.$store.state.users.balance).toFixed(2) : '******'}}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <router-link to="/wallet">
                            <div class="d-flex flex-column justify-content-center align-items-center">
                                <Iconify class="text-dark" icon="et:wallet" style="font-size: calc(2.1rem + 1.5vw) !important;" />
                            </div>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
            <img class="w-100"  style="height:8rem" src="../assets/home/agri.png">

        <div class="divider">
            <div class="w-100" style="border: 1.5px solid #3DAC78"></div>
        </div>

        <div class="py-4 px-3 pb-4" style="z-index: 1 !important;">
            <div class="row">
                <div class="col-3 p-0">
                    <router-link to="/daily/checkin">
                        <div class="d-flex flex-column justify-content-center align-items-center">
                        <Iconify 
                            icon="mdi:shield-check" 
                            style="font-size: calc(1.5rem + 1.5vw); color: rgb(61, 172, 120);" 
                        />
                        <div 
                            class="text-black fw-bold text-muted text-center" 
                            style="font-size: calc(0.4rem + 1.5vw);" 
                        >
                        {{$t('daily_checkin')}}
                        </div>
                        </div>
                    </router-link>
                </div>
                <div class="col-3 p-0">
                    <router-link to="/recharge">
                        <div class="d-flex flex-column justify-content-center align-items-center">
                        <Iconify 
                            icon="ri:cash-line" 
                            style="font-size: calc(1.5rem + 1.5vw); color: rgb(61, 172, 120);" 
                        />
                        <div 
                            class="text-black fw-bold text-muted text-center" 
                            style="font-size: calc(0.4rem + 1.5vw);" 
                        >
                        {{$t('recharge')}}
                        </div>
                        </div>
                    </router-link>
                </div>
                <div class="col-3 p-0">
                    <router-link to="/invite">
                        <div class="d-flex flex-column justify-content-center align-items-center">
                        <Iconify 
                            icon="basil:share-box-solid" 
                            style="font-size: calc(1.5rem + 1.5vw); color: rgb(61, 172, 120);" 
                        />
                        <div 
                            class="text-black fw-bold text-muted text-center" 
                            style="font-size: calc(0.4rem + 1.5vw);" 
                        >
                        {{$t('invite')}}
                        </div>
                        </div>
                    </router-link>
                </div>
                <div class="col-3 p-0">
                    <router-link to="/wallet">
                        <div class="d-flex flex-column justify-content-center align-items-center">
                        <Iconify 
                            icon="icon-park-outline:history-query" 
                            style="font-size: calc(1.5rem + 1.5vw); color: rgb(61, 172, 120);" 
                        />
                        <div 
                            class="text-black fw-bold text-muted text-center" 
                            style="font-size: calc(0.4rem + 1.5vw);" 
                        >
                        {{$t('history')}}
                        </div>
                        </div>
                    </router-link>
                </div>
                <div class="col-3 p-0 mt-2">
                    <router-link to="/investment">
                        <div class="d-flex flex-column justify-content-center align-items-center">
                        <Iconify 
                            icon="fluent:data-trending-20-filled" 
                            style="font-size: calc(1.5rem + 1.5vw); color: rgb(61, 172, 120);" 
                        />
                        <div 
                            class="text-black fw-bold text-muted text-center" 
                            style="font-size: calc(0.4rem + 1.5vw);" 
                        >
                        {{$t('trend')}}
                        </div>
                        </div>
                    </router-link>
                </div>
                <div class="col-3 p-0 mt-2">
                    <router-link to="/aboutus">
                        <div class="d-flex flex-column justify-content-center align-items-center">
                        <Iconify 
                            icon="akar-icons:star" 
                            style="font-size: calc(1.5rem + 1.5vw); color: rgb(61, 172, 120);" 
                        />
                        <div 
                            class="text-black fw-bold text-muted text-center" 
                            style="font-size: calc(0.4rem + 1.5vw);" 
                        >
                        {{$t('about_us')}}
                        </div>
                        </div>
                    </router-link>
                </div>
                <div class="col-3 p-0 mt-2">
                    <router-link to="/contactus">
                        <div class="d-flex flex-column justify-content-center align-items-center">
                        <Iconify 
                            icon="mage:contact-book" 
                            style="font-size: calc(1.5rem + 1.5vw); color: rgb(61, 172, 120);" 
                        />
                        <div 
                            class="text-black fw-bold text-muted text-center" 
                            style="font-size: calc(0.4rem + 1.5vw);" 
                        >
                        {{$t('contact')}}
                        </div>
                        </div>
                    </router-link>
                </div>
                <div class="col-3 p-0 mt-2">
                    <router-link to="/outer/link">
                        <div class="d-flex flex-column justify-content-center align-items-center">
                        <Iconify 
                            icon="fluent:building-government-16-regular" 
                            style="font-size: calc(1.5rem + 1.5vw); color: rgb(61, 172, 120);" 
                        />
                        <div 
                            class="text-black fw-bold text-muted text-center" 
                            style="font-size: calc(0.4rem + 1.5vw);" 
                        >
                        {{$t('regulatory_unit')}}
                        </div>
                        </div>
                    </router-link>
                </div>
            </div>
            <!-- <div class="row">
                <div class="col-3">
                    <router-link to="/daily/checkin">
                        <div class="d-flex flex-column justify-content-center align-items-center">
                            
                            <div>
                                <div class="text-black" style="font-size: calc(0.5rem + 1.5vw) !important;">
                                    {{$t('daily_checkin')}}
                                </div>
                            </div>
                        </div>
                    </router-link>
                </div>
                <div class="col-3 pe-0">
                    <router-link to="/recharge">
                        <div class="d-flex flex-column justify-content-center align-items-center">
                            
                            <div>
                                <div class="text-black" style="font-size: calc(0.5rem + 1.5vw) !important;">
                                    {{$t('recharge')}}
                                </div>
                            </div>
                        </div>
                    </router-link>
                </div>
                <div class="col-3">
                    <router-link to="/invite">
                        <div class="d-flex flex-column justify-content-center align-items-center">
                            
                            <div>
                                <div class="text-black" style="font-size: calc(0.5rem + 1.5vw) !important;">
                                    {{$t('invite')}}
                                </div>
                            </div>
                        </div>
                    </router-link>
                </div>
            </div> -->
        </div>
        
        <!-- <div class="p-3 pt-4">
            <div class="row mb-3">
                <div class="col-3">
                    <router-link to="/wallet">
                        <div class="d-flex flex-column">
                            <div class="d-flex flex-column justify-content-center align-items-center mb-2">
                                <div class="bg-white rounded-circle shadow-sm" style="padding: 0.8rem">
                                    <Iconify icon="icon-park-outline:history-query" style="font-size: calc(1.4rem + 1.5vw) !important; color: rgb(61, 172, 120)" />
                                </div>
                            </div>
                            <div>
                                <div class="d-flex flex-column justify-content-between align-items-center">
                                    <div class="text-wrap text-center" style="color: #2c3e50; font-size: calc(0.5rem + 1.5vw) !important">
                                        {{$t('history')}}
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </router-link>
                </div>
                <div class="col-3">
                    <router-link to="/investment">
                        <div class="d-flex flex-column">
                            <div class="d-flex flex-column justify-content-center align-items-center mb-2">
                                <div class="bg-white rounded-circle shadow-sm" style="padding: 0.8rem">
                                    <Iconify icon="fluent:data-trending-20-filled" style="font-size: calc(1.4rem + 1.5vw) !important; color: rgb(61, 172, 120)" />
                                </div>
                            </div>
                            <div>
                                <div class="d-flex flex-column justify-content-between align-items-center">
                                    <div class="text-wrap text-center" style="color: #2c3e50; font-size: calc(0.5rem + 1.5vw) !important">
                                        {{$t('trend')}}
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </router-link>
                </div>
                <div class="col-3">
                    <router-link to="/aboutus">
                        <div class="d-flex flex-column">
                            <div class="d-flex flex-column justify-content-center align-items-center mb-2">
                                <div class="bg-white rounded-circle shadow-sm" style="padding: 0.8rem">
                                    <Iconify icon="akar-icons:star" style="font-size: calc(1.4rem + 1.5vw) !important; color: rgb(61, 172, 120)" />
                                </div>
                            </div>
                            <div>
                                <div class="d-flex flex-column justify-content-between align-items-center">
                                    <div class="text-wrap text-center" style="color: #2c3e50; font-size: calc(0.5rem + 1.5vw) !important">
                                        {{$t('about_us')}}
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </router-link>
                </div>
                <div class="col-3">
                    <router-link to="/contactus">
                        <div class="d-flex flex-column">
                            <div class="d-flex flex-column justify-content-center align-items-center mb-2">
                                <div class="bg-white rounded-circle shadow-sm" style="padding: 0.8rem">
                                    <Iconify icon="mage:contact-book" style="font-size: calc(1.4rem + 1.5vw) !important; color: rgb(61, 172, 120)" />
                                </div>
                            </div>
                            <div>
                                <div class="d-flex flex-column justify-content-between align-items-center">
                                    <div class="text-wrap text-center" style="color: #2c3e50; font-size: calc(0.5rem + 1.5vw) !important">
                                        {{$t('contact')}}
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </router-link>
                </div>
                
                
            </div>          
       </div> -->

        <div class="ps-2 pe-2 ">
            <van-swipe class="my-swipe" v-if="slides.length != 0" :autoplay="5000" indicator-color="white" style="max-height: 140px;">
                <van-swipe-item v-for="(slide , i) in slides" :key="i">
                    <img :src="image_path+'storage/carousels/'+slide.img" class="img-fluid" style="width: 100%;">
                </van-swipe-item>
            </van-swipe>
        </div>
        <div class="pt-1 m-3">
            <div class="">
                <!-- <div>
                    <div class="fs-6 fw-semibold">
                        {{$t('article_hub')}}
                        </div>
                </div> -->
                <div class="text-end">
                    <router-link to="/news/all">
                        <div class="" style="color: rgb(61, 172, 120);font-size: calc(.5rem + 1.5vw) !important;">
                            {{$t('see_all')}} <span><Iconify icon="uil:arrow-right"  /></span>
                        </div>
                    </router-link>
                </div>
            </div>
        </div>
        <div class="mb-5">

            <div class="p-0 border-bottom mb-3" v-for="(blog  , i) in blogNews" :key="i">
                <!-- <div class="card-body p-3">
                    <img id="blog_image" :src="image_path+'storage/images/'+blog.cover_picture" class="rounded" style="height : 9rem ; width : 100%">
                    <p class="mt-3" style="font-size: 15px" v-html="blog.short_content"></p>
                    <div class="d-flex justify-content-between align-items-center">
                        <div>
                            <div class="text-muted" style="font-size : 14px">{{blog.date == null ? '' : blog.date}}</div>
                        </div>
                        <div>
                            <div @click="newsDetail(blog)" class="btn btn-success" style="background-color: rgb(61, 172, 120); border-color: background-color: rgb(61, 172, 120); --bs-btn-padding-y: .2rem; --bs-btn-padding-x: .5rem; --bs-btn-font-size: .9rem;">
                                {{$t('read_more')}} 
                                <Iconify icon="uil:arrow-right" style="font-size: calc(1.2rem + 1.5vw) !important; " />
                            </div>
                        </div>
                    </div>
                </div> -->
                <div class="ps-3 pe-3 row">
                    <div class="col-8" @click="newsDetail(blog)">
                        <p class="fw-bold" style="font-size: 15px">{{ truncateText(blog.short_content, 15) }}</p>
                        <div class="text-muted" style="font-size : 13px ; position: relative ; bottom : 10px">{{blog.date == null ? '' : blog.date}}</div>
                    </div>
                    <div class="col-4 p-0 pe-1 m-0">
                        <img id="blog_image" :src="image_path+'storage/images/'+blog.cover_picture" class="rounded" style="height : 5rem ; width : 100%">
                    </div>
                </div>
                

            </div>
            <!-- <div class="mt-4 mb-5">
                <div class="p-2" >
                    <div class="px-4 " @click="newsDetail(blog)">
                        <div class="col-12 ps-0 pe-0">
                            <img id="blog_image" :src="image_path+'storage/images/'+blog.cover_picture" class="rounded" style="height : 6rem ; width : 100%">
                        </div>
                        <div class="col-12">
                            <h6 class="fw-bold" >{{ blog.title.substring(0,18) + ' ...' }}</h6>
                            <p id="new_content" style="colo" v-html="blog.short_content"></p>
                            <p class="text-muted" style="font-size : 14px">{{blog.date == null ? '' : blog.date}}</p>

                        </div>

                    </div>
                </div>
            </div> -->


        </div>

        <van-popup v-if="popup_showed == 1" v-model:show="show"   :style="{ width : '80%'  , 'border-radius' : '15px'}">
           <div class="card bg-dark text-white border-0">
                <div  class="border-0 d-flex justify-content-center bg-dark" style="height : 5rem ; background-color : #000000" >
                    <!-- <div class="bg-dark" style=" width: 70px ; height: 70px;border-radius: 50%;position : absolute ; top : 3rem"> -->
                        <img src="../assets/home/anic.png" width="55" class="rounded img-fluid" style="position : relative ; left : .6rem ; top : .5rem">
                    <!-- </div> -->
               </div>

                <h4 class="text-center" style="margin-top : 1rem" v-text="title"></h4>
                <div class="d-flex justify-content-center">
                    <div class="col-12 ps-3 pe-3">
                      <p class="cryptofs text-muted" style="font-family : Arial ; font-size : .9rem" v-html="content"></p>
                    </div>
                </div>
                <div class="d-flex justify-content-center border-0 bg-dark card-footer" style="">
                    <button @click="show = false"  class="btn text-white  btn-sm mb-2" style="border-radius : 15px ; width : 5rem ; background-color : #E25215">Close</button>
                    </div>
                </div>

        </van-popup>

        <van-popup  v-model:show="restrict_show"   :style="{ width : '80%'  , 'border-radius' : '15px'}">
           <div class="card bg-dark text-white border-0">
                <!-- <div  class="border-0 d-flex justify-content-center bg-dark" style="height : 5rem ; background-color : #000000" > -->
                    <!-- <div class="bg-dark" style=" width: 70px ; height: 70px;border-radius: 50%;position : absolute ; top : 3rem"> -->
                        <!-- <img src="../assets/home/anic.png" width="55" class="rounded img-fluid" style="position : relative ; left : .6rem ; top : .5rem"> -->
                    <!-- </div> -->
               <!-- </div> -->

                <!-- <h4 class="text-center" style="margin-top : 1rem" v-text="'title'"></h4> -->
                <div class="border-0 p-3">
                      <p class="mt-3 text-muted text-center" style="font-family : Arial ; font-size : 15px" v-html="restrict_content"></p>
                </div>
                <div class="d-flex justify-content-center border-0 bg-dark card-footer" style="">
                    <button @click="restrict_show = false"  class="btn text-white  btn-sm mb-2" style="border-radius : 15px ; width : 5rem ; background-color : #E25215">Close</button>
                    </div>
                </div>

        </van-popup>

        
    </div>
</template>

<script>
// import { Player, Video } from '@vime/vue-next';
import '@vime/core/themes/default.css';
import { ref } from 'vue';

export default {
    setup() {
        const show = ref(false);
        const restrict_show = ref(false);
        const showPopup = () => {
          show.value = true;
        };

        
        return {
           show,
           restrict_show,
           showPopup,

        };
    },

    components: {
        // Player,
        // Video,
    },
    data(){
        return{
            videos : null,
            blogNews : '',
            image_path : '',
            total_check_in : 0,

            content : null,
            restrict_content : null,
            popup_status : null,
            popup_showed : 0,
            title : null,
            slides : [],
            enduser_id : null,
            balance_show : true,

        }
    },
    
    computed: {
      player() {
        return this.$refs.player;
      },
    },
    
    methods:{
        truncateText(content, length) {
        // Create a temporary div element to parse HTML and extract text
        const div = document.createElement('div');
        div.innerHTML = content;

        // Get the plain text content
        const text = div.innerText || div.textContent;

        // Return the truncated text with '...' if it's too long
        return text.length > length ? text.substring(0, length) + ' ...' : text;
        },
        balanceHide(status){
            this.balance_show = status
        },

        newsDetail(blog){
            this.$store.commit('storeNewsDetail' , blog)
            this.$router.push('/news/detail')
        },
    
            
    },
    
    created(){
        setTimeout(() => {
            // console.log(localStorage.getItem('popup_showed'))
            this.popup_showed = localStorage.getItem('popup_showed') == 'false' ? 0 : 1;
        }, 1000);
        var elements = document.querySelectorAll(".mobile-bottom-nav");
        elements.forEach(function(element) {
            element.classList.remove("d-none");
        });

        let point = this; 

        this.image_path = process.env.VUE_APP_BASE_IMAGE_URL
        let enduser_id = this.$store.state.users.id
        this.enduser_id = enduser_id
        this.axios.post(process.env.VUE_APP_BASE_URL+'/blog/news/get',
        {'enduser_id' : enduser_id},
        {
            headers: {
                'Authorization': 'Bearer '+JSON.parse(localStorage.getItem('china_app_access_token')).access_token,
            }
        }
        ).then(function(response){
            // console.log(response.data)
            point.blogNews = response.data[0]
            point.videos = response.data[1]
            point.total_check_in = response.data[3]
            point.slides = response.data[4]
        }).catch((error)=>{
            if(error.response.status == 401){
                localStorage.removeItem('china_app_access_token')
                location.reload()
            }
        })

        // this.axios.post(process.env.VUE_APP_BASE_URL+'/popup',
        // {'enduser_id' : enduser_id, 'id' : 1},
        // {
        //     headers: {
        //         'Authorization': 'Bearer '+JSON.parse(localStorage.getItem('china_app_access_token')).access_token,
        //     }
        // }
        // ).then(function(response){
        //     // console.log(response)
        //     point.content = response.data[0]?.content
        //     point.title   = response.data[0]?.title
        //     point.show = response.data[0]?.status == 1 ? true : false

        
        //     if(response.data[1] != null){
        //             point.restrict_show = true
        //             point.restrict_content = response.data[1].content
        //     }
        // })
    },

    
}
</script>

<style>
    #head {
        /* height: 360px;
        width: 100%;
        background: url('../assets/home/home_bg.png') no-repeat center bottom;
        background-size: cover;
        position: absolute;
        z-index: 0; */
    }

    #topParent{
        margin-top: 0px;
        margin-bottom: 30px;
        /* height: 100vh; */
        overflow-y: scroll;
        overflow-x: hidden;
    }
    .mp4{
        text-align: center;
    }
    #f28{
        font-size: 14px;
    }
    #divider{
        height: 8px;
        background-color: rgb(243, 243, 243)
    }
    #newsdivider{
        height: 3px;
        background-color: rgb(243, 243, 243)
    }
    #new_content{
        font-size: 15px;
    }

    #vid{
        height: 30vh;
        width: 100%;
        object-fit: fill; 
    }
    
    b{
        font-size : 16px
    }
    .cryptofs{
        font-size : 12px
    }
    .coin_price{
        font-size : 13px;
        font-weight : bold;
        color : white
    }
    
    .video-container {
        width: 100%;
        /* Set to desired aspect ratio */
        /* padding-top: 56.25%; 16:9 Aspect Ratio */
        position: relative;
  }
  .vp {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .my-swipe .van-swipe-item {
    color: #fff;
    font-size: 20px;
    line-height: 150px;
    text-align: center;
    background-color: #000000;
  }
</style>
