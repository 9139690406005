<template>
    <div class="" style="height : 100vh ; overflow-y : hidden">
     
     <div class="row  fixed-top">
         <div class="">
             <div class="d-flex flex-column justify-content-center align-items-center">
                <Iconify v-if="setting == ''" class="text-white fw-bold" style="position : absolute ; top : 25px ; left : 30px; font-size: 19px !important; color: #3dac78 !important;" icon="weui:back-outlined" @click="$router.go(-1)"></Iconify>

                <div class="fs-5 fw-semibold my-2" style="color: #3dac78 !important;">
                    <h6 class="fw-bold mt-3 text-center">{{$t('modify_information')}}</h6>
                </div>
            </div>
         </div>
        
     </div>
 
     <div class="p-3" id="detail_body" v-if="setting == ''">
        <div class="">

            <div class="d-flex flex-column card bg-white shadow-sm rounded-3 p-3 mb-3 border-0" style="">
                <div @click="setting = 1" to="/myteam" class="d-flex justify-content-between align-items-center px-1 ">
                    <div class="text-dark fs-6">
                        {{$t('change_password')}}
                    </div>
                    <div>
                        <Iconify class="text-dark" icon="weui:arrow-outlined" style="font-size: calc(1.7rem + 1.5vw) !important; color: #3dac78 !important;" />

                    </div>
                </div>
            </div>

            <div class="d-flex flex-column card bg-white shadow-sm rounded-3 p-3 mb-3 border-0" style="">
                <div @click="setting = 4" to="/myteam" class="d-flex justify-content-between align-items-center px-1 ">
                    <div class="text-dark fs-6">
                        {{$t('change_fund_password')}}
                    </div>
                    <div>
                        <Iconify class="text-dark" icon="weui:arrow-outlined" style="font-size: calc(1.7rem + 1.5vw) !important; color: #3dac78 !important;" />

                    </div>
                </div>
            </div>

            <div class="d-flex flex-column card bg-white shadow-sm rounded-3 p-3 mb-3 border-0" style="">
                <div @click="setting = 2" to="/verified" class="d-flex justify-content-between align-items-center px-1">
                    <div class="text-dark fs-6">
                        {{$t('change_profile_photo')}}
                    </div>
                    <div>
                        <Iconify class="text-dark" icon="weui:arrow-outlined" style="font-size: calc(1.7rem + 1.5vw) !important; color: #3dac78 !important;" />

                    </div>
                </div>
            </div>

            <div class="d-flex flex-column card bg-white shadow-sm rounded-3 p-3 border-0" style="">
                <div @click="setting = 3" to="/usersetting" class="d-flex justify-content-between align-items-center px-1">
                    <div class="text-dark fs-6">
                        {{$t('language')}}
                    </div>
                    <div>
                        <Iconify class="text-dark" icon="weui:arrow-outlined" style="font-size: calc(1.7rem + 1.5vw) !important; color: #3dac78 !important;" />

                    </div>
                </div>
            </div>
        </div>
     </div>

     <div class="p-3" id="detail_body">
         
        <div  v-if="setting == 1">
            <div class="card bg-white shadow-sm mt-2 border-0">
                <div class="card-body">
                    <div class="mb-3">
                    <label class="mb-2">{{$t('old_password')}}</label>
                    <input v-model="oldPassword" type="text" class="form-control border-0" style="background-color: #eee !important;">
                    </div>
                    <div class=" mb-3">
                        <label class="mb-2">{{$t('new_password')}}</label>
                    <input v-model="NewPassword" type="text" class="form-control border-0" style="background-color: #eee !important;">
                    </div>
                    <div class=" mb-3">
                        <label class="mb-2">{{$t('confirm_password')}}</label>

                    <input v-model="ConfirmPassword" type="text" class="form-control border-0" style="background-color: #eee !important;">
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-6">
                    <button class="btn col-12 mt-3 text-white" @click="setting = ''" style="background-color: #3dac78;">{{$t('back')}}</button>
                </div>
                <div class="col-6">
                    <button :disabled="loader ? true : false" class="btn col-12 mt-3 text-white" @click="resetPassword()" style="background-color: #3dac78;">{{$t('submit')}}
                        <div class="lds-dual-ring" v-if="loader"></div>
                    </button>
                </div>
            </div>
        </div>

        <div  v-if="setting == 4">
            <div class="card bg-white shadow-sm mt-2 border-0">
                <div class="card-body">
                    <div class="mb-3">
                    <label class="mb-2">{{$t('old_fund_password')}}</label>
                    <input v-model="oldFundPassword" type="text" class="form-control border-0" style="background-color: #eee !important;">
                    </div>
                    <div class=" mb-3">
                        <label class="mb-2">{{$t('new_fund_password')}}</label>
                    <input v-model="NewFundPassword" type="text" class="form-control border-0" style="background-color: #eee !important;">
                    </div>
                    <div class=" mb-3">
                        <label class="mb-2">{{$t('confirm_fund_password')}}</label>

                    <input v-model="ConfirmFundPassword" type="text" class="form-control border-0" style="background-color: #eee !important;">
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-6">
                    <button class="btn col-12 mt-3 text-white" @click="setting = ''" style="background-color: #3dac78;">{{$t('back')}}</button>
                </div>
                <div class="col-6">
                    <button :disabled="loader ? true : false" class="btn col-12 mt-3 text-white" @click="resetFundPassword()" style="background-color: #3dac78;">{{$t('submit')}}
                        <div class="lds-dual-ring" v-if="loader"></div>
                    </button>
                </div>
            </div>
        </div>
        

         <div v-if="setting == 2">
            <div v-if="preview == null" class="border rounded bg-white p-2 mt-2">
                
                <div class=" bg-white rounded p-2" >
                    <div class="mb-2 d-flex justify-content-center">
                        <input type="file" style="display: none" v-on:change="verifyImage($event)" id="recharge_image_upload" name='file'>
                        <label title="Image" for="recharge_image_upload"> 
                            <Iconify class="mt-3" icon="ion:cloud-upload-outline" style="font-size: calc(2.1rem + 1.5vw) !important; color: #3dac78 !important;" />
                        </label>
                    </div>
                    <p class="text-danger text-center" style="font-size: 12px; color: #3dac78 !important;" >*{{$t('upload_pp')}}</p>
                </div>
                <div v-if="preview != null" class="border rounded p-2 mt-2">
                    <img  :src="preview" class="img-fluid mt-3" width="60">
                    <fa class="text-danger float-end" style="" :icon="['fas', 'times']" @click="removeImage()"></fa>
                </div>
            </div>
           <div v-else class="border rounded p-2 mt-2">
                <img  :src="preview" class="img-fluid mt-3" width="60">
                <fa class="text-danger float-end" style="" :icon="['fas', 'times']" @click="removeImage(3)"></fa>
            </div>

            <div class="row">
                    <div class="col-6">
                        <button class="btn col-12 mt-3 text-white" @click="setting = ''" style="background-color: #3dac78;">{{$t('back')}}</button>
                    </div>
                    <div class="col-6">
                        <button :disabled="loader ? true : false" class="btn col-12 mt-3 text-white" @click="updateProfile()" style="background-color: #3dac78;">{{$t('submit')}}
                            <div class="lds-dual-ring" v-if="loader"></div>
                        </button>
                    </div>
                </div>
         </div>

         <div v-if="setting == 3">
            <select v-model="$i18n.locale" @change="lng_change($i18n.locale)" class="form-control border-0" style="font-size: 16px;">
                <option
                v-for="locale in $i18n.availableLocales"
                :key="`locale-${locale}`"
                :value="locale"
                >
                {{ locale == 'ch' ? 'Chinese' : 'English' }}
                </option>
            </select>

            

            <div class="row d-flex justify-content-end">
                <div class="col-12 ">
                    <button class="btn col-12 mt-3 btn-sm text-white" @click="setting = ''" style="background-color: #3dac78;">{{$t('back')}}</button>
                </div>
            </div>
         </div>
     </div>
 
     
     </div>
</template>
 
 <script>
 import {showSuccessToast , showFailToast} from 'vant';

 export default {
    data(){
        return {
            setting : '',
            passwordReset : false,
            changeProfile : false,
            changeLanguage : false,
            preview : null,
            oldPassword : null,
            NewPassword : null,
            ConfirmPassword : null,
            loader : false,
            images_types : ['image/png' , 'image/jpg' , 'image/jpeg'],
            formData : new FormData(),
            oldFundPassword : null,
            NewFundPassword : null,
            ConfirmFundPassword : null,
        }
    },
     methods : {
        removeImage(){
            this.preview = null
        },

        lng_change(lng){
            let ln = lng == 'en' ? 'English' : 'Chinese'
            localStorage.setItem('ai_app_lng' , lng)
            showSuccessToast('Language changed to '+ln )
        },

        verifyImage(e){
            const file = e.target.files[0]
            let size   = file.size / (1024 * 1024);
            let actual_size = Number(size.toFixed(1))
            if(actual_size > 5){
                showFailToast('f_img_size')
                // this.$notify({ text: 'Failed to upload photo',type: "error",});
                return;
            }
            if(this.images_types.includes(file.type)){
                let link  = URL.createObjectURL(file)
                this.preview = link
                this.formData.append('image', file);
            }else{
                showFailToast('f_img_type')

                // showFailToast('Failed to upload photo')

                // this.$notify({ text: '上传照片失败',type: "error",});
            }
        },

        updateProfile(){
            if(this.preview != null){
                this.loader = true
                let enduser_id = this.$store.state.users.id
                this.formData.append('enduser_id' , enduser_id)
                let point = this;
                    this.axios.post(process.env.VUE_APP_BASE_URL+'/enduser/profile/update',
                        this.formData,
                            {
                                headers: {
                                    'Content-Type': 'multipart/form-data',
                                    'Authorization': 'Bearer '+JSON.parse(localStorage.getItem('china_app_access_token')).access_token,
                                }
                            }
                        ).then(function(response){
                            // console.log(response.data)
                        if(response.data.status_code == 200){
                            // console.log(response.data)
                            // point.$notify({ text: response.data.message,type: "",});
                            showSuccessToast(point.$t(response.data.message))
                            setTimeout(()=>{
                                location.reload()
                            },1000)
                        }else{
                            showSuccessToast(point.$t(response.data.message))

                            // point.$notify({ text: response.data.message,type: "error",});
                            point.loader = false
                        }
                        }).catch((error)=>{
                        if(error.response.status == 401){
                            localStorage.removeItem('china_app_access_token')
                            location.reload()
                        }
                    })

            }else{
                showFailToast('Missing required fields')

                // this.$notify({ text: '缺少必填字段',type: "error",});
            }
        },

        signout(){
            this.$swal.fire({
            title: '<span style="font-size : 16px">Are you sure to sign out ?</span>',
            showCancelButton: 'true',
            confirmButtonText: 'Confirm',
            cancelButtonText: 'Cancel',
            }).then((result) => {
            if (result.isConfirmed) {
                // let point = this;
                let enduser_id = this.$store.state.users.id
                    this.axios.post(process.env.VUE_APP_BASE_URL+'/enduser/signout',
                        {'enduser_id' : enduser_id},
                            {
                                headers: {
                                    'Authorization': 'Bearer '+JSON.parse(localStorage.getItem('china_app_access_token')).access_token,
                                }
                            }
                        ).then(function(response){
                            console.log(response.data)
                        if(response.data.status_code == 200){
                            localStorage.removeItem('china_app_access_token')
                            showSuccessToast('成功註銷')

                            // point.$notify({ text: '成功退出',type: "",});
                            setTimeout(()=>{
                                location.reload()
                            }, 2000)
                        }
                        }).catch((error)=>{
                        if(error.response.status == 401){
                            localStorage.removeItem('china_app_access_token')
                            location.reload()
                        }
                    })
                
            }
            })

        },
        resetPassword(){

            if(this.oldPassword != null && this.NewPassword != null && this.ConfirmPassword != null){
                if(this.NewPassword == this.ConfirmPassword){
                    this.loader = true
                    let point = this;
                    let enduser_id = this.$store.state.users.id
                        this.axios.post(process.env.VUE_APP_BASE_URL+'/enduser/reset/password',
                            {'enduser_id' : enduser_id , 'oldPassword' : this.oldPassword , 'NewPassword' : this.NewPassword , 'ConfirmPassword' : this.ConfirmPassword , 'type' : 'password'},
                                {
                                    headers: {
                                        'Authorization': 'Bearer '+JSON.parse(localStorage.getItem('china_app_access_token')).access_token,
                                    }
                                }
                            ).then(function(response){
                                console.log(response.data)
                            if(response.data.status_code == 200){
                                showSuccessToast(point.$t(response.data.message))
                                // point.$notify({ text: response.data.message,type: "",});
                                setTimeout(()=>{
                                    location.reload()
                                }, 1000)
                            }else{
                                point.loader = false
                                showFailToast(point.$t(response.data.message))
                                // point.$notify({ text: response.data.message,type: "error",});
                            }
                            }).catch((error)=>{
                            if(error.response.status == 401){
                                localStorage.removeItem('china_app_access_token')
                                location.reload()
                            }
                        })
                }else{
                    showFailToast('新密码和确认密码必须一致')

                    // this.$notify({ text: '密码不正确',type: "error",});
                }
            }else{
                showFailToast(this.$t('missing_fields'))

                // this.$notify({ text: '缺少必填字段',type: "error",});
            }
            
        },

        resetFundPassword(){

            if(this.oldFundPassword != null && this.NewFundPassword != null && this.ConfirmFundPassword != null){
                if(this.NewFundPassword == this.ConfirmFundPassword){
                    this.loader = true
                    let point = this;
                    let enduser_id = this.$store.state.users.id
                        this.axios.post(process.env.VUE_APP_BASE_URL+'/enduser/reset/password',
                            {'enduser_id' : enduser_id , 'oldPassword' : this.oldFundPassword , 'NewPassword' : this.NewFundPassword , 'ConfirmPassword' : this.ConfirmFundPassword , 'type' : 'fund_password'},
                                {
                                    headers: {
                                        'Authorization': 'Bearer '+JSON.parse(localStorage.getItem('china_app_access_token')).access_token,
                                    }
                                }
                            ).then(function(response){
                                console.log(response.data)
                            if(response.data.status_code == 200){
                                showSuccessToast(point.$t(response.data.message))
                                // point.$notify({ text: response.data.message,type: "",});
                                setTimeout(()=>{
                                    location.reload()
                                }, 1000)
                            }else{
                                point.loader = false
                                showFailToast(point.$t(response.data.message))
                                // point.$notify({ text: response.data.message,type: "error",});
                            }
                            }).catch((error)=>{
                            if(error.response.status == 401){
                                localStorage.removeItem('china_app_access_token')
                                location.reload()
                            }
                        })
                }else{
                showFailToast('新密码和确认密码必须一致')
                    // this.$notify({ text: '密码不正确',type: "error",});
                }
            }else{
                showFailToast(this.$t('missing_fields'))

                // this.$notify({ text: '缺少必填字段',type: "error",});
            }
            
        }
     }
 }
 </script>
 
 <style>
     .header{
         background-color: #773DF5;
     }
     #detail_body{
         margin-top: 4rem;
     }
     #detail_body .input-group-text{
         font-size: 12px;
     }
 </style>