<template>
    <div style="overflow-x: hidden !important;">
        <div class="bg-image" id="profile_head">
            
            <div class="p-4 pb-2" 
                style="background: linear-gradient(90deg, rgba(61,172,120,.2) 0%, rgba(255,255,255,1) 100%);">
                <div class="d-flex">
                    <router-link to="/myteam">
                        <div class="me-2">
                         <Iconify class="text-dark" icon="line-md:arrow-left" style="position:relative ; bottom:5px" />
                         </div>
                    </router-link>
                    <h6 class="text-dark m-0 w-100 text-center">
                        {{$t('team_balance')}}
                    </h6>
                </div>
            </div>

            <div class="divider">
               <div class="w-100" style="border: 1.2px solid #3DAC78"></div>
            </div>
        </div>

        <div class="p-2" >
            <div class="text-dark rounded" style="background: linear-gradient(90deg, rgba(240,238,238,1) 24%, rgba(61,172,120,.3) 100%);">
                <div class="p-4 card-body">
                    <div class="d-flex justify-content-between">
                        
                        <div>
                            <div class="d-flex  flex-column justify-content-between">
                                <div class="fs-6 mb-2">
                                    <span class="text-muted">{{$t('team_recharge')}} (¥)</span>
                                </div>
                                
                            </div>
                        </div>
                        <div class="d-flex flex-column justify-content-center align-items-center">
                            {{Number(this.$store.state.users.recharge_amount_under) + Number(this.$store.state.users.recharge_amount)}}
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <div class="p-2" >
            <div class="text-dark rounded" style="background: linear-gradient(90deg, rgba(240,238,238,1) 24%, rgba(61,172,120,.3) 100%);">
                <div class="p-4 card-body">
                    <div class="d-flex justify-content-between">
                        
                        <div>
                            <div class="d-flex  flex-column justify-content-between">
                                <div class="fs-6 mb-2">
                                    <span class="text-muted">{{$t('team_withdraw')}} (¥)</span>
                                </div>
                                
                            </div>
                        </div>
                        <div class="d-flex flex-column justify-content-center align-items-center">
                            {{Number(this.$store.state.users.withdraw_amount_under) + Number(this.$store.state.users.withdraw_amount)}}
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <div class="p-2" >
            <div class="text-dark rounded" style="background: linear-gradient(90deg, rgba(240,238,238,1) 24%, rgba(61,172,120,.3) 100%);">
                <div class="p-4 card-body">
                    <div class="d-flex justify-content-between">
                        
                        <div>
                            <div class="d-flex  flex-column justify-content-between">
                                <div class="fs-6 mb-2">
                                    <span class="text-muted">{{$t('team_balance')}} (¥)</span>
                                </div>
                                
                            </div>
                        </div>
                        <div class="d-flex flex-column justify-content-center align-items-center">
                            {{(Number(this.$store.state.users.recharge_amount_under) + Number(this.$store.state.users.recharge_amount)).toFixed(2) - Number(Number(this.$store.state.users.withdraw_amount_under) + Number(this.$store.state.users.withdraw_amount)).toFixed(2)}}
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
 import {showSuccessToast} from 'vant';

export default {
    data(){
        return {
            balance : 0,
            crypto_balance : 0,
            profile : null,
            level : null,
            vip_level : '',
            verified : false,
            image_path : process.env.VUE_APP_BASE_IMAGE_URL,
            enduser_id : null,
            balance_show : true,

        }
    },
    methods : {
        balanceHide(status){
            this.balance_show = status
        },

        goToChat(){
            window.location.href = 'ultronchat://open.ultron.app';
        },
        signout(){
            let point = this;
            this.$swal.fire({
            title: '<span style="font-size : 16px">'+ point.$t('logout_confirmation_text') +' ?</span>',
            showCancelButton: 'true',
            confirmButtonText: point.$t('confirm'),
            cancelButtonText: point.$t('cancel'),
            }).then((result) => {
            if (result.isConfirmed) {
                // let point = this;
                let enduser_id = this.$store.state.users.id
                    this.axios.post(process.env.VUE_APP_BASE_URL+'/enduser/signout',
                        {'enduser_id' : enduser_id},
                            {
                                headers: {
                                    'Authorization': 'Bearer '+JSON.parse(localStorage.getItem('china_app_access_token')).access_token,
                                }
                            }
                        ).then(function(response){
                            // console.log(response.data)
                        if(response.data.status_code == 200){
                            localStorage.removeItem('china_app_access_token')
                            showSuccessToast('成功退出')

                            // point.$notify({ text: '成功退出',type: "",});
                            setTimeout(()=>{
                                location.reload()
                            }, 2000)
                        }
                        }).catch((error)=>{
                        if(error.response.status == 401){
                            localStorage.removeItem('china_app_access_token')
                            location.reload()
                        }
                    })
                
            }
            })

        },
    },

    created(){
        let point = this;
        console.log(this.$store.state.users)
        let enduser_id = this.$store.state.users.id
        this.enduser_id = this.$store.state.users.id
        this.axios.post(process.env.VUE_APP_BASE_URL+'/get/user/investment/record',
        {'enduser_id' : enduser_id},
        {
            headers: {
                'Authorization': 'Bearer '+JSON.parse(localStorage.getItem('china_app_access_token')).access_token,
            }
        }
        ).then(function(response){
            // console.log(response.data.data.ai_investment[0].investment)
            if(response.data.data.ai_investment[0].investment == null){
                // console.log(response.data.data.ai_investment[0].investment)
                point.balance = 0
            }else{
                 let ai_investment = response.data.data.ai_investment[0].investment;
                 point.balance = Number(ai_investment).toFixed(2)
            }

            if(response.data.data.crypto_investment[0].investment == null){
                point.crypto_balance = 0
            }else{
                let crypto_investment = response.data.data.crypto_investment[0].investment;
                point.crypto_balance = Number(crypto_investment).toFixed(2)
            }
        }).catch((error)=>{
            // console.log(error)
            if(error.response.status == 401){
                localStorage.removeItem('china_app_access_token')
                location.reload()
            }
        })


        this.axios.post(process.env.VUE_APP_BASE_URL+'/realname/verification/check',
        {'enduser_id' : enduser_id},
        
        {
            headers: {
                'Authorization': 'Bearer '+JSON.parse(localStorage.getItem('china_app_access_token')).access_token,
            }
        }
        ).then(function(response){
            
            if(response.data.status_code == 200){
                point.verified = true
            }
        })

        this.axios.post(process.env.VUE_APP_BASE_URL+'/get/user/balance',
            {'enduser_id' : enduser_id},
            
            {
                headers: {
                    'Authorization': 'Bearer '+JSON.parse(localStorage.getItem('china_app_access_token')).access_token,
                }
            }
            ).then(function(response){
                // console.log(response)
                point.level = response.data.level_id
                point.vip_level = response.data.vip_level
                point.profile = response.data.profile
            })
    }
}
</script>

<style>
#profile_head {
    /* width: 100%;
    background: url('../assets/home/profile_bg.png') no-repeat;
    background-size: cover;
    position: relative; */
}
#mineqrcard{
    /* margin-top : 17rem */
    /* position: relative;
	top:17rem; 
    margin-top : 3rem;*/
    margin-bottom : 3rem;
	width: 100%;
}
#totalPayment{
    
    position: absolute;
	top:10rem;
	width: 100%;
    
}
#userSetting{
    position: absolute;
	top:1rem;
	width: 100%;
    
}

.mineBg {
  /* background-image: url("../assets/mine/bg3.png"); */
  background-repeat: no-repeat;
  background-size: cover;
  height: 230px;
  background-position: center;
}

#fm2 {
    font-size: 12px;
    
    margin-left: 1rem;
    font-weight :500
}
#mineqrcard .list-group-item{
    padding : 12px 10px 12px 10px;
}
.usn{
    font-family : Arial;
    font-size: 18px;
    color: white;
    font-weight: bold;
}
.crown_text{
    /* position : absolute; */
    font-size: 11px;
    /* right : 3rem; */
    /* top : 1.7rem; */
    color : white
}

</style>